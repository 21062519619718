import { Outlet } from 'react-router-dom'
import { lazy, Suspense } from 'react'
import { SuspensePage } from '../../Pages'

const GamificationProvider = lazy(() =>
  import('../../Context').then((module) => ({
    default: module.GamificationProvider,
  }))
)
const GamificationPublicProvider = lazy(() =>
  import('../../Context').then((module) => ({
    default: module.GamificationPublicProvider,
  }))
)
const CompetitionProvider = lazy(() =>
  import('../../Context').then((module) => ({
    default: module.CompetitionProvider,
  }))
)
const QuestionsProvider = lazy(() =>
  import('../../Context').then((module) => ({
    default: module.QuestionsProvider,
  }))
)
const PredictorProvider = lazy(() =>
  import('../../Context/PredictorContext/PredictorContext').then(
    (module) => ({ default: module.PredictorProvider })
  )
)
const PickemProvider = lazy(() =>
  import('../../Context').then((module) => ({
    default: module.PickemProvider,
  }))
)

export const GamificationContextLayout: React.FC = () => {
  return (
    <Suspense fallback={<SuspensePage />}>
      <GamificationProvider>
        <GamificationPublicProvider>
          <CompetitionProvider>
            <QuestionsProvider>
              <PredictorProvider>
                <PickemProvider>
                    <Outlet />
                </PickemProvider>
              </PredictorProvider>
            </QuestionsProvider>
          </CompetitionProvider>
        </GamificationPublicProvider>
      </GamificationProvider>
    </Suspense>
  )
}
