// All players for Contract Table
export const fetchAllPlayers = `
query($season: Int!, $week:Int!) {
    allPlayerFantasy(year: $season, week: $week) {
        profileUrl
        firstName
        lastName
        slug
        injuryStatus
        injuryDescription
        officialId
        status
        rosterStatus
        contractStatus
        currentTeam {
            fullName
            urlLogo
            officialId
            locationCode
            conference
        }
        currentGame(year: $season, week: $week){
            id
            startTime
            eventStatus
            seasonSegment
            week
            gameStatsSummary
            homeTeam {
                officialId
                urlLogo
                fullName
                locationCode
            }
            awayTeam {
                officialId
                urlLogo
                fullName
                locationCode
            }
        }
        fantasyInfo(week: $week){
            fantasyPlayerInfoId
            salary
            weekPts
            avgPPG
            projectedPoints
            realtimeProjectedPoints
            position
            seasonTotalPts
            eventId
            gameNumber
        }
    }
}
`

export const fetchFantasyPlayerQuery = `
query($id:ID!, $year:Int!, $week:Int!, $segment: StatSegment!, $includeCS: Boolean){
    player(id:$id, forYear:$year){
        injuryStatus
        injuryDescription
        officialId
        jerseyNum
        profileUrl
        firstName
        lastName
        fantasyInfo(week: $week){
            seasonWeeklyPts {
                eventId
                totalPts
            }
            salary
            avgPPG
            projectedPoints
            realtimeProjectedPoints
            position
            seasonTotalPts
            weekPts
            eventId
            gameNumber
            fantasyPlayerInfoId
            seasonWeeklyPts {
                week
                totalPts
                eventId
                gameNumber
                projectedPoints
                salary
            }
        }
        currentGame(year: $year, week: $week){
            startTime
            eventStatus
            homeTeam {
                urlLogo
                fullName
                officialId
                locationCode
                conference
            }
            awayTeam {
                urlLogo
                fullName
                officialId
                locationCode
                conference
            }
        }
        currentTeam {
            fullName
            urlLogo
            officialId
            locationCode
            conference
        }
        stats(segment:$segment){
            gamesPlayed
        }
        allEvents(year:$year, includeCS:$includeCS){
            eventId: externalId
            week
            startTime
            eventStatus
            homeTeam {
              officialId
              locationCode
              conference
            }
            awayTeam {
              officialId
              locationCode
              conference
            }
            homeScore
            visitorScore
            playerEventStats{
              goals
              points
              onePointGoals
              twoPointGoals
              assists
              turnovers
              causedTurnovers
              groundBalls
              faceoffsWon
              faceoffsLost
              saves
              foRecord
              savePct
              shots
              twoPointShots
              goalsAgainst
              twoPtGaa
          }
      }
    }
  }
`
