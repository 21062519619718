import { CompiledPicksType, FantasyGroupsInfo, FantasyPositionTypes, LeaderboardSortBy, PlayerBonusFieldType } from '../../Api'
import { PageNavList } from '../../Components/Navbar/PageNav/PageNav'
import {
  FANTASY_CHALLENGERS_URL,
  FANTASY_GROUPS_URL,
  FANTASY_PLAYERS_URL,
  FANTASY_TEAM_URL,
} from '../../Routing/urls'
import { useLocation } from 'react-router-dom'

export const FANTASY_SALARY_CAP = 200
export type FantasyButtonOptions = 'save' | 'clear'

export type FantasyButtons = {
  [key: string]: FantasyButtonOptions
}

export const FANTASY_BTNS: FantasyButtons = {
  SAVE: 'save',
  CLEAR: 'clear',
}
export const privateFantasyGroups: FantasyGroupsInfo[] = [
  {
    name: 'PLL Staff',
    groupId: 37,
    highlightedMembersTitle: '',
    weekPrizes: [],
  },
  {
    name: 'PLL JRs',
    groupId: 36,
    highlightedMembersTitle: '',
    weekPrizes: [],
  },
  {
    name: "Captain's Club",
    groupId: 67,
    highlightedMembersTitle: '',
    weekPrizes: [],
  },
  {
    name: 'All Stars',
    groupId: 68,
    highlightedMembersTitle: '',
    weekPrizes: [],
  },
  {
    name: 'MVPs Only',
    groupId: 69,
    highlightedMembersTitle: '',
    weekPrizes: [],
  },
  {
    name: 'The Keystone Group',
    groupId: 70,
    highlightedMembersTitle: '',
    weekPrizes: [],
  },
]

export const fantasyPages: PageNavList[] = [
  {
    pageId: 1,
    pageTitle: 'team',
    link: FANTASY_TEAM_URL,
  },
  {
    pageId: 2,
    pageTitle: 'players',
    link: FANTASY_PLAYERS_URL,
  },
  {
    pageId: 3,
    pageTitle: 'challengers',
    link: FANTASY_CHALLENGERS_URL,
  },
  {
    pageId: 4,
    pageTitle: 'groups',
    link: FANTASY_GROUPS_URL,
  },
]

export const fantasyGroupChallengePages: PageNavList[] = [
  {
    pageId: 1,
    pageTitle: 'leaderboards',
    link: ''
  },
  {
    pageId: 2,
    pageTitle: 'myGroups',
    link: ''
  },
  {
    pageId: 3,
    pageTitle: 'rules&Prizes',
    link: ''
  },
]

export const GetCurrentPage = () => {
  const url = useLocation().pathname
  let num = 1
  fantasyPages.map((pg) => {
    if (url.includes(pg.pageTitle)) {
      num = pg.pageId
    }
  })

  if (url.includes('matchup')) {
    num = 3
  }
  return num
}

export type RowPosList = {
  posId: string
  pos: string
  posIdx: number
}

export const fantasyMatchupRows: RowPosList[] = [
  {
    posId: 'A1',
    pos: 'A',
    posIdx: 0,
  },
  {
    posId: 'A2',
    pos: 'A',
    posIdx: 1,
  },
  {
    posId: 'M1',
    pos: 'M',
    posIdx: 0,
  },
  {
    posId: 'M2',
    pos: 'M',
    posIdx: 1,
  },
  {
    posId: 'D',
    pos: 'D',
    posIdx: 0,
  },
  {
    posId: 'G',
    pos: 'G',
    posIdx: 0,
  },
  {
    posId: 'FO',
    pos: 'FO',
    posIdx: 0,
  },
]

export const fantasyChampSeriesRows: RowPosList[] = [
  {
    posId: 'F1',
    pos: 'F',
    posIdx: 0,
  },
  {
    posId: 'F2',
    pos: 'F',
    posIdx: 1,
  },
  {
    posId: 'F3',
    pos: 'F',
    posIdx: 2,
  },
  {
    posId: 'F4',
    pos: 'F',
    posIdx: 3,
  },
  {
    posId: 'G1',
    pos: 'G',
    posIdx: 0,
  },
  {
    posId: 'G2',
    pos: 'G',
    posIdx: 1,
  },
]

export const fantasyPositionTypes: FantasyPositionTypes[] = [
  { label: 'Attack', value: 'A' },
  { label: 'Midfield', value: 'M' },
  { label: 'Faceoff', value: 'FO' },
  { label: 'Short Stick D-Mid', value: 'SSDM' },
  { label: 'Long Stick Midfield', value: 'LSM' },
  { label: 'Defense', value: 'D' },
  { label: 'Goalie', value: 'G' },
  { label: 'Field', value: 'F'},
]

type SegmentNameType = {
  type: string
  value: LeaderboardSortBy | string
}

export const GROUP_LEADERBOARD_SEGMENT_NAMES: SegmentNameType[] = [
  {type: 'Weekly', value: 'weekly'},
  {type: 'Season', value: 'season'},
  {type: 'Playoffs', value: 'postSeason'}
]

export const playerCardHeaderStats = [
  {
    title: 'Games',
    firstValue: 'stats',
    secondValue: 'gamesPlayed',
  },
  {
    title: 'SZN Points',
    firstValue: 'fantasyInfo',
    secondValue: 'seasonTotalPts',
  },
  {
    title: 'Avg Points',
    firstValue: 'fantasyInfo',
    secondValue: 'avgPPG',
  },
]

// Player Fields in order to map through, get title, long field, or field points
export const PlayerFields = {
  onePointGoals: ['1G', 'onePointGoals', 10],
  twoPointGoals: ['2G', 'twoPointGoals', 20],
  assists: ['A', 'assists', 5],
  saves: ['Sv', 'saves', 3],
  causedTurnovers: ['CT', 'causedTurnovers', 5],
  groundBalls: ['GB', 'groundBalls', 1],
  faceoffsWon: ['FOW', 'faceoffsWon', 0.8],
  faceoffsLost: ['FOL', 'faceoffsLost', -0.5],
  goalsAgainst: ['GA', 'goalsAgainst', -1],
  twoPtGaa: ['2ptGA', 'twoPtGaa', -2],
  turnovers: ['TO', 'turnovers', -3],
}

export const playerBonusFields: PlayerBonusFieldType[] = [
  {
    title: '1G',
    field: 'onePointGoals',
    bonus: 5,
    bonusMetric: 3,
  },
  {
    title: 'A',
    field: 'assists',
    bonus: 5,
    bonusMetric: 3,
  },
  {
    title: 'Sv',
    field: 'saves',
    bonus: 5,
    bonusMetric: 15,
  },
  {
    title: 'CT',
    field: 'causedTurnovers',
    bonus: 5,
    bonusMetric: 3,
  },
]

export const defaultCompiledPicks: CompiledPicksType = {
  A: [],
  M: [],
  D: [],
  F: [],
  G: [],
  FO: [],
}
