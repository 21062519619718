import React from 'react'
import { Footer } from './footer'
import { AdUnit } from '../Ads/AdUnit'

type FooterParams = {
  showPLLNav?: boolean
  showFixedAd: boolean
  zIndex?: number
}

export const FooterComponent: React.FC<FooterParams> = ({
  showPLLNav = true,
  showFixedAd,
  zIndex
}) => {
  
  return (
    <>
      {showFixedAd && <AdUnit bannerType="bannerSecond" bottomFixed zIndex={zIndex}  />}
      {showPLLNav && <Footer />}
    </>
  )
}
