import React, { useState, useEffect } from 'react'
import { GeneralSnackbar } from '../Snackbar/GeneralSnackbar'
import { ShareBtnCon } from './styles'
import { IosShareIcon } from '../LazyComponents'

type ShareButtonParams = {
  shareLink: string
  size: 'lg' | 'sm'
  border: boolean
  theme?: 'light' | 'dark'
  label?: string
  direction?: 'row' | 'column'
}

export const ShareButton: React.FC<
  React.PropsWithChildren<ShareButtonParams>
> = ({
  label,
  shareLink,
  size,
  border = false,
  theme = 'light',
  direction = 'column',
}) => {
  const [copied, setCopied] = useState<string | null>(null)
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false)

  useEffect(() => {
    // Set timer to clear copied so user can click multiple times
    if (copied) {
      setShowSnackbar(!showSnackbar)
      const timer = setTimeout(() => {
        setCopied('')
        setShowSnackbar(false)
      }, 3000)
      return () => clearTimeout(timer)
    }
    return
  }, [copied])

  const handleClick = async () => {
    try {
      await navigator.clipboard.writeText(shareLink)
      if (showSnackbar) return //Don't allow multiple clicks
      setCopied('Copied!')
    } catch (err) {
      console.log('Error copying link', err)
    }
  }

  return (
    <>
      <ShareBtnCon
        onClick={handleClick}
        size={size}
        border={border}
        sTheme={theme}
        direction={direction}
      >
        <IosShareIcon className="icon" />
        {label && <p className="label">{label}</p>}
      </ShareBtnCon>
      {showSnackbar && (
        <GeneralSnackbar
          text="Copied the share link 🙌"
          color="green"
          show={showSnackbar}
        />
      )}
    </>
  )
}
