import { getFirUserAndStatus } from '../../Utils'
import { formatImg } from '../Users/userUtils'
import { FirebaseUser, User } from '../types/users.types'

export type FavType = 'topics' | 'favPlayers' | 'favTeam'

export const NO_USER: User = {
  isLoggedIn: false,
  isAnonymous: false,
  uid: null,
  email: null,
  favTeam: null
}

/**
 * Get currently logged user data from firebase
 */
export const getCurrentUser = async (): Promise<FirebaseUser> => {
  const { user } = await getFirUserAndStatus()
  if (user) {
    // logged in user
    let firUser: FirebaseUser = {
      isLoggedIn: user.isAnonymous ? false : true,
      isAnonymous: user.isAnonymous,
      uid: user.uid,
      favTeam: null
    }
    if (user?.displayName) {
      firUser['firstName'] = user.displayName.split(' ')[0]
      firUser['lastName'] = user.displayName.split(' ')[1]
    }
    if (user?.email) {
      firUser['email'] = user.email
    }
    if (user?.phoneNumber) {
      firUser['phone'] = user.phoneNumber
    }
    if (user?.photoURL) {
      firUser['profileUrl'] = user.photoURL
    }

    return firUser
  }
  // not logged in
  return NO_USER
}

/**
 * Get currently logged user data
 */
export const setUpdatedUserData = async (userRes: any): Promise<User> => {
  const { user } = await getFirUserAndStatus()
  if (user) {
    // logged in user
    let userObj = {
      isLoggedIn: !user.isAnonymous,
      isAnonymous: user.isAnonymous,
      uid: user.uid,
      firstName: userRes?.firstName,
      lastName: userRes?.lastName,
      email: userRes?.email,
      profileUrl: userRes.profileUrl ? formatImg(userRes.profileUrl) : '',
      phone: user?.phoneNumber ?? userRes?.phone ?? '',
      birthday: userRes?.birthday ?? '',
      gamification: userRes?.gamification ?? false,
      boostedGames: userRes?.boostedGames ?? [],
      cashTag: userRes?.cashTag ?? '',
      favTeam: userRes?.favTeam ?? '',
      favPlayers: userRes?.favPlayers ?? [],
      zip: userRes?.zip ?? '',
    }
    if (userRes?.username) {
      userObj['username'] = userRes.username
    }
    if (userRes?.pllNation) {
      userObj['pllNation'] = userRes.pllNation
    }
    return userObj
  }
  // not logged in
  return NO_USER
}
