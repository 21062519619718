import { styled } from '@mui/system'
import { colors } from '../../Components/consts'

type VidConParams = {
  width?: string
}

export const SingleFullVidCon = styled('div')<VidConParams>(
  ({ theme, width }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(3, 1),
    background: colors.darkBackground,
    borderRadius: 6,
    width: width ?? '90%',
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(2, 0),
      width: width ?? '100%',
    },
  })
)
