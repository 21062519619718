import { validateEmail } from '../../Components/Auth/authHelpers'

export const validateProfileEdits = (
  profileData: {
    [key: string]: any
  },
  requireEmail?: boolean
) => {
  let errors: { [key: string]: string } = {}

  //Email
  if (requireEmail && profileData?.alternateEmail) {
    const validated = validateEmail(profileData.alternateEmail)
    validated.errorMsg && (errors.alternateEmail = validated.errorMsg)
  }
  //Zip
  if (profileData?.zip && profileData.zip.length < 5) {
    //Factor in Canadian zip codes
    errors.zip = 'Please enter a valid zip code.'
  }

  return errors
}

export const validateBirthday = (
  year?: string | null,
  month?: string | null,
  day?: string | null
): string => {
  // Early return if any values are missing
  if (!year || !month || !day) {
    return 'Please enter a valid birthday.'
  }

  // Convert to numbers and validate format
  const parsedYear = parseInt(year)
  const parsedMonth = parseInt(month)
  const parsedDay = parseInt(day)
  const currentYear = new Date().getFullYear()
  const age = currentYear - parsedYear
  const today = new Date()
  const birthDate = new Date(parsedYear, parsedMonth - 1, parsedDay)
  
  if (
    isNaN(parsedYear) ||
    isNaN(parsedMonth) ||
    isNaN(parsedDay) ||
    year.length !== 4 ||
    parsedMonth < 1 ||
    parsedMonth > 12 ||
    parsedDay < 1 ||
    age > 85 ||
    age < 8 ||
    birthDate > today
  ) {
    return 'Please enter a valid birthday.'
  }

  // Get days in the specified month
  const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate()
  if (parsedDay > daysInMonth) {
    return 'Please enter a valid day for the selected month.'
  }

  return ''
}
