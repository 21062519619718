import { FantasyGroup } from '../../Api'
import { REGULAR_SEASON_END_WEEK } from '../Season/seasonHelpers'

export const verifyGroupName = (name: string) => {
  let error = ''
  let disabled = true
  if (name.length < 3) {
    error = '* Group name must be at least 3 characters'
    disabled = true
  }

  if (name.length > 50) {
    error = '* Group name must be less than 50 characters'
    disabled = true
  }

  //Set button to enabled
  disabled = false

  return {
    error,
    disabled,
  }
}

export const verifyGroupDesc = (name: string) => {
  let error = ''
  let disabled = false

  if (name.length > 250) {
    error = '* Group name must be less than 50 characters'
    disabled = true
  }

  //Set button to enabled
  disabled = false

  return {
    error,
    disabled,
  }
}

export const hasJoinedFantasyGroup = (
  groupId: number,
  groups: FantasyGroup[]
) => {
  return groups.some((fg) => fg.id === groupId)
}

export const setPlayoffSegmentName = (
  segment: string,
  week: number,
  year: number
) => {
  if (segment === 'Weekly') {
    let quarters = REGULAR_SEASON_END_WEEK[year] + 1
    let semis = REGULAR_SEASON_END_WEEK[year] + 2
    let championship = REGULAR_SEASON_END_WEEK[year] + 3

    return week === quarters
      ? 'Quarterfinals'
      : week === semis
      ? 'Semifinals'
      : week === championship
      ? 'Championship'
      : segment
  }

  return segment
}

export const getPlayoffNameShort = (
  week: number,
  year: number
) => {
  let quarters = REGULAR_SEASON_END_WEEK[year] + 1
  let semis = REGULAR_SEASON_END_WEEK[year] + 2
  let championship = REGULAR_SEASON_END_WEEK[year] + 3

  return week === quarters
    ? 'QF'
    : week === semis
    ? 'SF'
    : week === championship
    ? 'Champ'
    : week
}
