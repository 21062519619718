import React, {
  createContext,
  useState,
  useEffect,
  useContext,
} from 'react'
import { getPublicGamificationProfile } from '../../Api'
import { PLLNationUser } from '../../Api/types/users.types'
import { usePublicProfileUrlParams } from '../utils'
import { GamificationContext } from './GamificationContext'
import { logEvent } from '../../Utils'

type GamificationPublicContextType = {
  publicUser: PLLNationUser | null
  loadingProfile: boolean
  isPublic: boolean
}

export const GamificationPublicContext =
  createContext<GamificationPublicContextType | null>(null)

export const GamificationPublicProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const { publicId } = usePublicProfileUrlParams()
  const { gamificationConfig, getUserRewards } = useContext(
    GamificationContext
  )!
  const [loadingProfile, setLoadingProfile] = useState<boolean>(true)
  const [publicUser, setPublicUser] = useState<PLLNationUser | null>(
    null
  )
  const [isPublic, setIsPublic] = useState<boolean>(!!publicId)

  useEffect(() => {
    if (!publicId) {
      setLoadingProfile(false)
      setIsPublic(false)
      return
    }
    getUserRewards(publicId)
    setIsPublic(true)
    fetchPublicProfile()
  }, [gamificationConfig, publicId])

  const fetchPublicProfile = async () => {
    let publicProfile = await getPublicGamificationProfile(publicId)
    if (!publicProfile) {
      console.log('no public profile')
      setLoadingProfile(false)
      return
    }
    setPublicUser(publicProfile)
    logEvent('public_profile_click', {
      public_profile_id: publicProfile,
    })
    setLoadingProfile(false)
  }

  return (
    <GamificationPublicContext.Provider
      value={{
        loadingProfile,
        publicUser,
        isPublic,
      }}
    >
      {children}
    </GamificationPublicContext.Provider>
  )
}
