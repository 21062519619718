import React, { useContext, useEffect, useState } from 'react'
import {
  sendVideoComplete,
  sendVideoPause,
  sendVideoStart,
} from '../../Utils/Video/videoUtils'
import { SingleFullVidCon } from './styles'
import { logBrazeEvent } from '../../Utils'
import { gamificationEngagements } from '../../Constants'
import { sendGamificationEngagement } from '../../Api'
import { VideoPlayer } from './VideoPlayer'
import { getVideoPlayerOptions } from '../../Constants/video'
import { getFeedItemById } from '../../Api/Feed/feed.api'
import { DEFAULT_VIDEO_PLAYER_ID } from '../../Configs/Video/config'
import { Desc } from '../../Components/Typography/Text.styles'
import { colors } from '../../Components/consts'
import { AuthContext } from '../../Api/firebase'
import { FeedItem } from '../../Api/Feed/feed.types'

type FullVideoParams = {
  mediaId: string
  mediaItem?: FeedItem | null
  aspectRatio?: string
  autoplay?: boolean
  muted?: boolean
}

export const FullVideoCon: React.FC<FullVideoParams> = ({
  mediaId,
  mediaItem,
  autoplay = true,
  muted = false,
}) => {
  const auth = useContext(AuthContext)!
  const [video, setVideo] = useState<FeedItem | null>(null)
  const [videoPlayCount, setVideoPlayCounter] = useState<number>(0)
  const [videoFetching, setVideoFetching] = useState<boolean>(false)
  const [duration, setDuration] = useState<number>(0)
  const [watchCredit, setWatchCredit] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  useEffect(() => {
    if (mediaItem) {
      //Video already fetched
      setVideo(mediaItem)
      setDuration(mediaItem?.duration || 0)
      setVideoFetching(false)
      return
    }
    getSingleVideoById(mediaId)
  }, [])

  const getSingleVideoById = async (mediaId: string) => {
    if (mediaId) {
      const feedItemRes = await getFeedItemById({
        feedId: `${mediaId}`,
        includeVideos: true,
        contentType: 'video',
      })
      if(feedItemRes?.error){
        setErrorMessage(feedItemRes.error)
        setVideoFetching(false)
        return
      }

      if (feedItemRes?.data) {
        setVideo(feedItemRes?.data)
        setDuration(feedItemRes?.data?.duration || 0)
        setVideoFetching(false)
        return
      }
      setVideoFetching(false)
    }
    setVideoFetching(false)
  }

  const onVideoComplete = () => {
    let params = {
      videoLength: duration > 0 ? duration : '',
      mediaId: mediaId,
    }
    sendVideoComplete(params)
  }

  const onVideoStart = () => {
    if (videoPlayCount > 0) return
    let params = {
      videoLength: duration > 0 ? duration : '',
      mediaId: mediaId,
    }
    setVideoPlayCounter(1)
    sendVideoStart(params)
    if (auth.isLoggedIn && auth.uid) {
      logBrazeEvent(gamificationEngagements.VIDEO_WATCH)
      if(!watchCredit){
        setWatchCredit(true)
        Promise.resolve(
          sendGamificationEngagement(auth.uid, gamificationEngagements.VIDEO_WATCH, mediaId)
        )
      }
    }
  }

  const onVideoPause = (watchedTime: number) => {
    if (watchedTime === 0 || duration === 0) return
    let pctWatched = watchedTime / duration
    let params = {
      mediaId: mediaId,
      seconds_watched: watchedTime,
      percentage_watched: pctWatched,
    }
    sendVideoPause(params)
  }

  if(!videoFetching && errorMessage){
    return (
      <SingleFullVidCon>
        <Desc color={colors.white}>{errorMessage}</Desc>
      </SingleFullVidCon>
    )
  }

  return (
    <SingleFullVidCon>
      {!videoFetching && video && (
        <VideoPlayer
          key={mediaId}
          playerId={DEFAULT_VIDEO_PLAYER_ID}
          videoId={video?.mediaId}
          options={getVideoPlayerOptions({
            autoplay: autoplay,
            aspectRatio: video?.aspectRatio ?? '16:9',
            muted: muted,
          })}
          onVideoStart={onVideoStart}
          onVideoComplete={onVideoComplete}
          onVideoPause={onVideoPause}
          aria-label={`Video player for ${video?.mediaId}`}
        />
      )}
    </SingleFullVidCon>
  )
}