import React, { useEffect, useState } from 'react'
import { ReleaseParams } from '../../Context/utils'
import { Img } from '../Images/Img'
import { FullVideoCon } from '../Videos/FullVideoCon'

type MediaItemParams = {
  params: ReleaseParams
}

export const MediaItem: React.FC<MediaItemParams> = ({ params }) => {
  const [mediaType, setMediaType] = useState<'video' | 'image' | null>(
    null
  )
  useEffect(() => {
    if (params?.imageUrl) {
      return setMediaType('image')
    }
    if (params?.mediaId) {
      setMediaType('video')
    }
  }, [])

  return (
    <div className="mediaCon">
      {mediaType === 'image' && (
        <Img src={params.imageUrl} alt={'Preview image for Release'} />
      )}
      {mediaType === 'video' && (
        <FullVideoCon mediaId={params.mediaId} />
      )}
    </div>
  )
}
