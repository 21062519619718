import { FC, useContext, useState } from 'react'
import { ReleaseParams } from '../../Context/utils'
import { AuthContext } from '../../Api/firebase'
import { useNavigate } from 'react-router-dom'
import { DEFAULT_EXTRA_LOGIN_FIELDS } from '../../Constants/login'
import { logBrazeEvent, logFirEvent } from '../../Utils'
import { claimCode, sendGamificationEngagement } from '../../Api'
import { ShareButton } from '../Buttons/ShareButton'
import { Btn } from '../Buttons/Btn'
import { ANALYTICS_TAGS } from '../../Constants'

type ReleaseBtnParams = {
  params: ReleaseParams
  handleSnackbarShow: (
    message: string,
    color: 'green' | 'red' | 'blue'
  ) => void
}

export const ReleaseActionBtn: FC<ReleaseBtnParams> = ({
  params,
  handleSnackbarShow,
}) => {
  const navigate = useNavigate()
  let { isLoggedIn, uid, login } = useContext(AuthContext)!
  const defaultBtnText = params?.actionTitle ?? 'Submit'
  const [buttonText, setButtonText] = useState<string>(defaultBtnText)
  const getAnalyticsParams = () => {
    return {
      actionType: params?.actionType,
      actionTitle: params?.actionTitle,
      actionLink: params?.actionLink,
      brazeEventID: params?.brazeEventID,
      gamificationEvent: params?.gamificationEvent,
      id: params?.id,
      login: isLoggedIn
    }
  }

  const handleOnClick = async () => {
    logFirEvent(
      ANALYTICS_TAGS.feature_release_did_tap_primary_action,
      getAnalyticsParams()
    )
    if (!isLoggedIn) return login(DEFAULT_EXTRA_LOGIN_FIELDS)

    logBrazeEvent(
      ANALYTICS_TAGS.feature_release_did_tap_primary_action,
      getAnalyticsParams()
    )

    if (params?.brazeEventID) {
      logBrazeEvent(params.brazeEventID)
    }
    if (params?.gamificationEvent && uid) {
      sendGamificationEngagement(
        uid,
        params.gamificationEvent,
        params.id
      )
    }

    if (shouldUseSubmitClaim()) {
      //Not a link, don't send, just submit
      return await submitClaim(params.actionLink)
    }
    setButtonText('Submitting...')
    if (params?.actionLink && params.actionLink.includes('http')) {
      setTimeout(() => {
        setButtonText('Success!')
      }, 400)
      setTimeout(() => {
        window.location.href = params.actionLink
      }, 450)
      return
    }

    setButtonText(defaultBtnText)
  }

  const shouldUseSubmitClaim = () => {
    if (
      params?.actionType === 'claim' &&
      params?.actionLink &&
      !params.actionLink.includes('http')
    ) {
      return true
    }
    return false
  }

  const submitClaim = async (code: string) => {
    setButtonText('Claiming...')
    const res = await claimCode(code)
    if (res?.success) {
      setButtonText('Success!')
      handleSnackbarShow('Success!', 'green')
      const timer = setTimeout(
        () => navigate && navigate('/pll-nation'),
        3000
      )
      return () => clearTimeout(timer)
    } else {
      handleSnackbarShow('Something went wrong.', 'red')
      setButtonText(defaultBtnText)
      return
    }
  }

  const renderButton = () => {
    if (params?.actionType === 'share') {
      return (
        <ShareButton
          shareLink={params?.actionLink}
          border
          size="lg"
          label={buttonText}
          theme="dark"
          direction="row"
        />
      )
    }
    if (params?.actionLink) {
      return (
        <Btn
          btnStyle="primary"
          uiTheme="dark"
          handleClick={handleOnClick}
        >
          {buttonText}
        </Btn>
      )
    }
    return
  }

  return <>{renderButton()}</>
}
