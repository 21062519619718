import React, {
  createContext,
  useState,
  useEffect,
  useContext,
} from 'react'
import {
  CreatedGroup,
  FantasyGroup,
  LeaderboardSortBy,
  SingleFantasyGroupCompetition,
  fetchFantasyGroupById,
  fetchFantasyGroupsByYear,
  joinFantasyGroupById,
} from '../../Api'
import { SEASON } from '../../Configs/config'
import { AuthContext } from '../../Api/firebase'
import { hasJoinedFantasyGroup, logEvent } from '../../Utils'
import { FantasyContext } from './FantasyContext'
import { SnackbarData } from '../../Api/types/picks.types'
import {
  createFantasyGroup,
  deleteGroup,
  fetchAllFantasyGroupCompetitions,
  fetchFantasyUserGroups,
  joinFantasyGroupCompetition,
  removeUserFromGroup,
  userLeaveFantasyGroup,
} from '../../Api'
import { GeneralSnackbar } from '../../Components/Snackbar/GeneralSnackbar'
import { ANALYTICS_TAGS } from '../../Constants/analytics'
import { logFirEvent } from '../../Utils/analytics/firAnalytics'
import { FantasyPrize } from '../../Api/Fantasy'
import { StorageContext } from '../StorageContext'
import { STORAGE_NAMES } from '../../Constants/storage'

type FantasyGroupContextType = {
  myGroupList: FantasyGroup[]
  publicGroupList: FantasyGroup[]
  singleGroup: FantasyGroup | null
  loading: boolean
  groupPrize: FantasyPrize | null
  allFantasyCompetitions: SingleFantasyGroupCompetition[]
  showModal: { [key: string]: { show: boolean; modalTitle: string } }
  fetchFantasyGroup: (
    groupId: string,
    sortBy: LeaderboardSortBy | string
  ) => Promise<FantasyGroup | null>
  joinFantasyGroup: (group: FantasyGroup) => Promise<boolean>
  createNewFantasyGroup: (groupInfo: {
    [key: string]: string
  }) => Promise<FantasyGroup | null>
  clearSingleGroup: () => void
  updateGroupPrize: (prize: FantasyPrize | null) => void
  leaveFantasyGroup: (groupId: number) => Promise<boolean>
  deleteFantasyGroup: (groupId: number) => Promise<boolean>
  removeUserFromFantasyGroup: (
    groupId: number,
    deleteUserId: string
  ) => Promise<boolean>
  joinCompetition: (
    competitionId?: number,
    groupId?: number
  ) => Promise<boolean>
  handleShowModal: (modal: string) => void
}

export const FantasyGroupContext =
  createContext<FantasyGroupContextType | null>(null)

export const FantasyGroupProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  let { login, uid } = useContext(AuthContext)!
  const { isStorageReady, getStoredData } = useContext(StorageContext)!
  let { fantasyUser, currentWeek } = useContext(FantasyContext)!
  const [myGroupList, setMyGroupList] = useState<FantasyGroup[]>([])
  const [publicGroupList, setPublicGroupList] = useState<
    FantasyGroup[]
  >([])
  const [singleGroup, setSingleGroup] = useState<FantasyGroup | null>(
    null
  )
  const [loading, setLoading] = useState<boolean>(true)
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
  const [snackbarData, setSnackbarData] = useState<SnackbarData | null>(
    null
  )
  const [groupPrize, setGroupPrize] = useState<FantasyPrize | null>(
    null
  )
  const [allFantasyCompetitions, setAllFantasyCompetitions] = useState<
    SingleFantasyGroupCompetition[]
  >([])
  const [showModal, setShowModal] = useState<{
    [key: string]: {
      show: boolean
      modalTitle: string
    }
  }>({
    createGroup: { show: false, modalTitle: 'createGroup' },
    enterGroups: { show: false, modalTitle: 'enterGroups' },
  })

  useEffect(() => {
    if (isStorageReady) {
      fetchFantasyGroupList()
      fetchAllGroupCompetitions()
    }
  }, [isStorageReady])

  useEffect(() => {
    //Refilter groups on user or public group changes
    if (fantasyUser && publicGroupList.length > 0) {
      filterGroups(publicGroupList)
    }
    fetchUserFantasyGroups()
  }, [fantasyUser])

  const fetchFantasyGroupList = async () => {
    const { data } = await getStoredFantasyGroups()
    if (!data) {
      setLoading(true)
    }
    try {
      const groups = await fetchFantasyGroupsByYear(SEASON)
      if (groups && groups.length > 0) {
        filterGroups(groups)
      }
      setLoading(false)
    } catch (error) {
      console.error('Error fetching fantasy groups:', error)
      setLoading(false)
    }
  }

  const getStoredFantasyGroups = async (): Promise<{
    data: any
    refetch: boolean
  }> => {
    const storedData = await getStoredData(STORAGE_NAMES.fantasy_groups)
    if (storedData?.data) {
      filterGroups(storedData.data)
      setLoading(false)
    }
    return storedData
  }

  const fetchAllGroupCompetitions = async () => {
    const competitions = await fetchAllFantasyGroupCompetitions(SEASON)
    if (competitions && competitions.length > 0) {
      setAllFantasyCompetitions(competitions)
    }
  }

  const filterGroups = (groups: FantasyGroup[]) => {
    let pGroups: FantasyGroup[] = []
    if (!fantasyUser) {
      //No user, set all groups to public
      setPublicGroupList(groups)
      return
    }

    //Filter groups into joined vs not joined
    groups.map((g) => {
      let found = hasJoinedFantasyGroup(g.id, myGroupList)
      if (!found) {
        pGroups.push(g)
      }
    })
    setPublicGroupList(pGroups)
  }

  const fetchFantasyGroup = async (
    groupId: string,
    sortBy: LeaderboardSortBy | string
  ) => {
    setLoading(true)
    if (currentWeek === null) {
      setLoading(false)
      return null
    }
    const parsedGroupId = parseInt(groupId)
    if (Number.isNaN(parsedGroupId)) {
      // Not a number, do not fetch
      setLoading(false)
      return null
    }
    const group = await fetchFantasyGroupById(
      parsedGroupId,
      currentWeek,
      sortBy
    )
    if (!group) {
      setLoading(false)
      return null
    }
    setSingleGroup(group)
    setLoading(false)
    return group
  }

  const fetchUserFantasyGroups = async () => {
    if (!uid) return
    let userGroups = await fetchFantasyUserGroups(
      uid,
      currentWeek,
      SEASON
    )
    if (userGroups) {
      setMyGroupList(userGroups)
    }
  }

  const joinFantasyGroup = async (group: FantasyGroup) => {
    if (!fantasyUser) {
      console.log('No fantasy user')
      login()
      return false
    }

    const successRes = await joinFantasyGroupById(
      group.id,
      fantasyUser.firebaseId
    )
    if (!successRes?.success) {
      toggleSnackbar({
        text: successRes?.error || 'Failed to join group',
        color: 'red',
      })
      return false
    } else {
      updateGroupsList(group)
      logFirEvent(ANALYTICS_TAGS.fantasy_group_join, {
        group_id: group.id,
        group_name: group.name,
        week: currentWeek,
        group_count: group.totalUsers,
      })
      return true
    }
  }

  const leaveFantasyGroup = async (groupId: number) => {
    if (!uid) return false
    let userLeft = await userLeaveFantasyGroup(uid, groupId)
    if (userLeft) {
      toggleSnackbar({
        text: 'Successfully left group.',
        color: 'green',
      })
      return true
    }
    toggleSnackbar({ text: 'Failed to leave group.', color: 'red' })
    return false
  }

  const deleteFantasyGroup = async (groupId: number) => {
    if (!uid) return false
    let deletedGroup = await deleteGroup(uid, groupId)
    if (deletedGroup) {
      toggleSnackbar({
        text: 'Successfully deleted group.',
        color: 'green',
      })
      return true
    }
    toggleSnackbar({ text: 'Failed to delete group.', color: 'red' })
    return false
  }

  const removeUserFromFantasyGroup = async (
    groupId: number,
    deleteUserId: string
  ) => {
    if (!uid) return false
    let deletedUser = await removeUserFromGroup(
      uid,
      groupId,
      deleteUserId
    )
    if (deletedUser) {
      let newGroup = await fetchFantasyGroupById(groupId, currentWeek)
      if (newGroup) {
        setSingleGroup(newGroup)
      }
      toggleSnackbar({
        text: 'Successfully removed user.',
        color: 'green',
      })
      return true
    }
    toggleSnackbar({ text: 'Failed to remove user.', color: 'red' })
    return false
  }

  const handleShowModal = (modal: string) => {
    if (modal === 'enterGroups') {
      logEvent(
        ANALYTICS_TAGS.fantasy_competition_my_groups_enter_click,
        {
          competition_id: allFantasyCompetitions[0]?.competitionId,
          competition_name: allFantasyCompetitions[0]?.name,
        }
      )
    }
    setShowModal({
      ...showModal,
      [modal]: {
        show: !showModal[modal].show,
        modalTitle: showModal[modal].modalTitle,
      },
    })
  }

  const createNewFantasyGroup = async (groupInfo: {
    [key: string]: string
  }) => {
    if (!fantasyUser) {
      console.log('No fantasy user')
      handleShowModal('createGroup')
      login()
      return Promise.resolve(null)
    }
    let groupObj: CreatedGroup = {
      userId: fantasyUser.firebaseId,
      groupName: groupInfo.groupName,
    }
    if (groupInfo?.description)
      groupObj.description = groupInfo.description

    const createdGroup = await createFantasyGroup(groupObj)
    if (!createdGroup) {
      toggleSnackbar({
        text: 'Failed to create group',
        color: 'red',
      })
      return null
    } else {
      updateGroupsList(createdGroup)
      toggleSnackbar({
        text: `Successfully created ${createdGroup.name}. Time to invite friends!`,
        color: 'green',
      })
      logEvent(ANALYTICS_TAGS.fantasy_group_create, {
        group_id: createdGroup.id,
        group_name: createdGroup.name,
        group_description: createdGroup?.description || '',
        week: currentWeek,
      })
      //Return the created group to redirect
      return createdGroup
    }
  }

  const updateGroupsList = async (joinedGroup: FantasyGroup) => {
    let myGroups = [...myGroupList]
    let newPublicGroups = publicGroupList.filter(
      (g) => g.id !== joinedGroup.id
    )
    setPublicGroupList(newPublicGroups)

    //Make sure it doesn't already exist in my groups
    let alreadyJoined = myGroups.find((g) => g.id === joinedGroup.id)
    if (alreadyJoined) return
    setMyGroupList([...myGroups, joinedGroup])
  }

  const toggleSnackbar = (message: SnackbarData) => {
    setSnackbarData(message)
    setShowSnackbar(true)
    //show for only 3 seconds
    setTimeout(() => {
      setShowSnackbar(false)
      setSnackbarData(null)
    }, 3000)
  }

  const updateGroupPrize = (prize: FantasyPrize | null) => {
    setGroupPrize(prize)
  }

  const clearSingleGroup = () => {
    singleGroup && setSingleGroup(null)
  }

  const joinCompetition = async (
    competitionId?: number,
    groupId?: number
  ) => {
    if (!groupId || !uid || !competitionId) return false
    let joined = await joinFantasyGroupCompetition(
      competitionId,
      groupId,
      uid
    )
    if (joined) {
      toggleSnackbar({
        text: 'Your group has joined the competition!',
        color: 'green',
      })
      return true
    }
    toggleSnackbar({
      text: 'There was an issue joining the competition.',
      color: 'red',
    })
    return false
  }

  return (
    <FantasyGroupContext.Provider
      value={{
        myGroupList,
        publicGroupList,
        singleGroup,
        loading,
        groupPrize,
        allFantasyCompetitions,
        showModal,
        fetchFantasyGroup,
        joinFantasyGroup,
        createNewFantasyGroup,
        updateGroupPrize,
        clearSingleGroup,
        leaveFantasyGroup,
        deleteFantasyGroup,
        removeUserFromFantasyGroup,
        joinCompetition,
        handleShowModal,
      }}
    >
      {children}
      {showSnackbar && snackbarData && (
        <GeneralSnackbar
          text={snackbarData.text}
          color={snackbarData.color}
          show={showSnackbar}
        />
      )}
    </FantasyGroupContext.Provider>
  )
}
