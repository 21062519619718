import {
  FantasyConfig,
  FantasyPlayer,
  FantasyPlayerInfo,
  PlayerWeekFantasyData,
} from '../../Api/Fantasy/fantasy.types'
import { colors } from '../../Components/consts'

export type FantasyPlayerSortKeys = keyof FantasyPlayerInfo

/**
Sorts fantasy players by first week points, then projected points, then salary
*/
export const sortFantasyPlayers = (
  list: FantasyPlayer[],
  ascDesc: -1 | 1
) => {
  const filteredList = list.filter(player => player.eventId !== null)
  return filteredList.sort((a, b) => {
    let A = 0,
      B = 0,
      A2 = 0,
      B2 = 0,
      A3 = 0,
      B3 = 0

    // Weekly points
    A = a?.totalPoints ?? 0
    B = b?.totalPoints ?? 0

    // Projected points
    A2 = a?.projectedPoints ?? 0
    B2 = b?.projectedPoints ?? 0

    // Salary
    A3 = a?.salary ?? 0
    B3 = b?.salary ?? 0

    if (ascDesc < 0) {
      return A - B || A2 - B2 || A3 - B3
    } else {
      return B - A || B2 - A2 || B3 - A3
    }
  })
}

/**
 * Used when calculating fantasy user's total. Show projected points or realtime projected points if game is live
 */
export const selectProjectedPoints = (
  playerId: number,
  allPlayers: FantasyPlayer[]
) => {
  const player = matchPlayerInfo(playerId, allPlayers)
  if (!player) return 0.0
  const hasLiveGame = isGameLive(player)
  if (hasLiveGame) {
    //Live updating as game goes
    return player?.realTimeProjectedPoints || 0.0
  }
  if (player?.eventStatus && player.eventStatus > 1) {
    //Game ended, use final points
    return player?.totalPoints || 0.0
  }

  //Game hasn't started yet, use projected points
  return player?.projectedPoints || 0.0
}

/**
 * Show projected points or realtime projected points if game is live
 */
export const showProjectedPoints = (player: PlayerWeekFantasyData) => {
  const hasLiveGame = isGameLive(player)
  if (hasLiveGame) {
    //Live updating as game goes
    return player?.realTimeProjectedPoints || 0.0
  }
  return player?.projectedPoints || '-'
}

/**
If there is a minimum projected points, check if the player is over that amount to qualify
*/
export const isOverMinimumSalary = (
  player: PlayerWeekFantasyData,
  minSalary: number
) => {
  const sal = player?.salary || 0
  return sal >= minSalary
}

/**
Determins what color the fantasy and proj points should be
*/
export const getPointColors = (item: {
  week: number
  player: FantasyPlayer
  useColors: boolean
}) => {
  const eventStatus = item.player?.eventStatus ?? 0
  const hasLiveGame = eventStatus > 0 && eventStatus < 2
  let colorObj = {
    pointsColor: hasLiveGame === true ? colors.yellow : colors.white,
    projColor: colors.gray.gray40,
  }
  if (!hasLiveGame || !item.useColors) return colorObj

  const projectedPts = item.player?.projectedPoints || 0
  const realtimeProjPts = item.player?.realTimeProjectedPoints || 0
  if (realtimeProjPts > projectedPts) {
    colorObj.projColor = colors.green?.lightGreen
  }
  if (realtimeProjPts < projectedPts) {
    colorObj.projColor = colors.red
  }

  return colorObj
}

export const POSITION_ORDER = [
  { label: 'A', value: 'attack' },
  { label: 'M', value: 'midfield' },
  { label: 'D', value: 'defense' },
  { label: 'FO', value: 'faceoff' },
  { label: 'F', value: 'field' },
  { label: 'G', value: 'goalie' },
]

export const getSortedPositions = (
  fantasyConfig: FantasyConfig,
  currentWeek: number
) => {
  const posTypes = fantasyConfig.fantasyWeeks.find(
    (week) => week.week === currentWeek
  )?.playerCount

  if (posTypes) {
    const sortedPositions: {label: string, value: string}[] = []

    // Only add each position once if it exists in playerCount
    POSITION_ORDER.forEach((pos) => {
      if (posTypes[pos.value] && posTypes[pos.value] > 0) {
        sortedPositions.push(pos)
      }
    })
    return sortedPositions
  }
  return []
}

// Match a player by either their fantasyPlayerInfoId or officialId
export const matchPlayerInfo = (playerId: number | string, allPlayers: FantasyPlayer[]) => {
  let player: FantasyPlayer | null = null
  if (typeof playerId === 'number') {
    player = allPlayers.find((p) => p.fantasyPlayerInfoId === playerId) ?? null
  } else if (typeof playerId === 'string') {
    player = allPlayers.find((p) => p.officialId === playerId) ?? null
  }
  return player
}

/**
 * Checking if player's game is currently live
 */
export const isGameLive = (player: FantasyPlayer | PlayerWeekFantasyData) => {
  let now = Math.round(Date.now() / 1000)
  const eventStatus = player?.eventStatus ?? 0
  const currentlyPlaying = eventStatus === 1
  const pastStartTime = player?.startTime ? player.startTime > now : false

  return currentlyPlaying && pastStartTime
}