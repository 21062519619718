import { logFirEvent } from '../../Utils'

export const isBrazeInitialized = () => {
  return (
    typeof window !== 'undefined' && window.brazeInitialized === true
  )
}

export const initBraze = async (userId: string) => {
  if (process.env.REACT_APP_BRAZE_API_KEY) {
    if (isBrazeInitialized()) {
      return true
    }
    try {
      const { initialize, changeUser, openSession } = await import(
        '@braze/web-sdk'
      )
      let success = initialize(process.env.REACT_APP_BRAZE_API_KEY, {
        baseUrl:
          process.env.REACT_APP_BRAZE_API_URL || 'sdk.iad-05.braze.com',
        enableLogging: process.env.NODE_ENV === 'development',
        minimumIntervalBetweenTriggerActionsInSeconds: 10,
      })

      if (success) {
        changeUser(userId)
        openSession()
        window.brazeInitialized = true
        return true
      }
      return false
    } catch (error) {
      console.error('Error initializing Braze:', error)
      logFirEvent('brazeError_initBraze_f2p', {
        provider: 'braze',
        error: error,
        product: 'f2p',
      })
      return false
    }
  }
  return false
}
