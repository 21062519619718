import { API_ENDPOINTS } from '../utils/restEndpoints'
import { makeAPIRequest, QueryParams } from '../utils/request'
import { FeedItem, GetFeedParams } from './feed.types'

export const getFeedBySlug = async (
  params: GetFeedParams
): Promise<FeedItem[] | null> => {
  try {
    const response = await makeAPIRequest(API_ENDPOINTS.GET_FEED_BY_TAG, {
      method: 'GET',
      apiVersion: '3',
      queryParams: params as QueryParams,
    })
    if (!response?.data) {
      console.error(response.error?.message)
      return null
    }
    return response.data as FeedItem[]
  } catch (err) {
    console.log(err)
    return null
  }
}

export const getFeedItemById = async (
  params: GetFeedParams
): Promise<{ data: FeedItem | null; error: string | null } | null> => {
  try {
    const response = await makeAPIRequest(API_ENDPOINTS.GET_FEED_BY_ID, {
      method: 'GET',
      apiVersion: '3',
      queryParams: params as QueryParams,
    })
    if (!response?.data || response?.error) {
      return {
        data: null,
        error: response?.error?.message ?? 'Could not get item.',
      }
    }
    return {
      data: response.data,
      error: null,
    }
  } catch (err: any) {
    console.log(err)
    return {
      data: null,
      error: err?.error,
    }
  }
}
