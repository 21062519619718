import {
  Question,
  QuestionParams,
  fetchActiveQuestions,
  fetchQuestionsGroupByType,
} from '../../Api'
import { SEASON } from '../../Configs/config'
import { QUESTION_ANSWER_STATUSES } from '../../Constants'
import { Percent } from '../Fantasy/fantasyHelpers'

// FETCHING //
/**
 * Determine what type of question function to call based on type
 */
export const getQuestionSet = async (
  params: QuestionParams,
  uid?: string | null
): Promise<Question[]> => {
  let questions: Question[] = []
  let now = new Date()
  let currentTime = now.getTime() / 1000
  switch (params.qType) {
    case 'game-predictor':
      if (params?.week === 0 || params.week) {
        const groupQs = await getPredictorQuestions(
          params.week,
          SEASON,
          uid
        )
        const qsToSet = sortQuestions(groupQs || [])
        questions = qsToSet
      }
      break
    default:
      delete params.qType
      questions = uid
        ? await fetchActiveQuestions({ ...params, userId: uid })
        : await fetchActiveQuestions()
      questions = questions.filter(
        (q) => q?.startTime && q.startTime < currentTime
      )
  }
  return questions
}

const getPredictorQuestions = async (
  week: number,
  year: number,
  userId?: string | null
): Promise<Question[] | null> => {
  try {
    return await fetchQuestionsGroupByType(
      'game-predictor',
      userId,
      week,
      year
    )
  } catch (err) {
    console.log(err)
    return []
  }
}

// SORTING //
export const sortQuestions = (questions: Question[]) => {
  let sorted = questions.sort(
    (a, b) =>
      (a.groupSort ? a.groupSort : 0) - (b.groupSort ? b.groupSort : 0)
  )
  return sorted
}

export const sortAnswerChoices = (question: Question) => {
  let answers = question?.answerChoices
  if (!answers) return []
  let sorted = answers.sort(
    (a, b) => (a.sort ? a.sort : 0) - (b.sort ? b.sort : 0)
  )
  return sorted
}

// QUESTON LOGIC //

/**
 *
 * Check if question is still active
 */
export const isQuestionActive = (stop: number) => {
  let now = new Date()
  let currentTime = now.getTime() / 1000
  if (stop < currentTime) {
    //Question is locked
    return false
  }
  return true
}

/**
 *
 * Check if user can change their answer
 */
export const isQuestionChangable = (question: Question) => {
  if (!question.userAnswerCode) return true // Has not answered yet
  if (question.isChangeable) {
    return true
  }
  return false
}

export const shouldShowPercent = (question: Question) => {
  // Percent should always be shown
  if (question.showPctBefore) return true

  //Return after answering
  if (question.showPctAfter && question.userAnswerCode) return true

  //Show only when answer is closed
  if (!isQuestionActive(question.closeTime) && question.showPctAfter)
    return true

  return false
}

/**
 *
 * Update answer counts after user selects answer
 */
export const updateAnswerCounts = (
  question: Question,
  sel: string,
  prevSel: string | null
): Question => {
  let copiedQ = { ...question }
  let newSelected = copiedQ.answerChoices.findIndex(
    (a) => a.code === sel
  )
  let oldSelected = copiedQ.answerChoices.findIndex(
    (a) => a.code === prevSel
  )
  if (newSelected !== -1) {
    //increment new count by 1
    copiedQ.answerChoices[newSelected].count++
  }
  if (oldSelected !== -1) {
    //subtract old count by 1
    copiedQ.answerChoices[oldSelected].count--
  }
  copiedQ.userAnswerCode = sel
  return copiedQ
}

/**
 *
 * Check if user got answer correct
 */
export const checkCorrect = (
  q: Question,
  answerRowCode: string,
  hasClosed: boolean
) => {
  if (q.correctAnswer.length < 1)
    return QUESTION_ANSWER_STATUSES.NEUTRAL
  if (
    q.userAnswerCode === q.correctAnswer[0] &&
    answerRowCode === q.correctAnswer[0]
  ) {
    return QUESTION_ANSWER_STATUSES.CORRECT
  }
  if (
    q.userAnswerCode !== q.correctAnswer[0] &&
    answerRowCode === q.userAnswerCode
  ) {
    return QUESTION_ANSWER_STATUSES.INCORRECT
  }
  //Question closed & not answered
  if (
    hasClosed &&
    !q.userAnswerCode &&
    answerRowCode === q.correctAnswer[0]
  ) {
    return QUESTION_ANSWER_STATUSES.INCORRECT
  }
  return QUESTION_ANSWER_STATUSES.NEUTRAL
}

/**
 *
 * Calculate percent of users selected for specific answer choice
 */
export const calculateAnswerPercentage = (
  question: Question,
  answerCount: number
) => {
  let totalCount = 0
  question.answerChoices.map((a) => {
    totalCount = totalCount + a.count
  })
  if (totalCount === 0) return ''
  const fraction = answerCount / totalCount
  return Percent(fraction)
}

/**
 *
 * Check if question is still active
 */
export const getPredictorScore = (questions: Question[]) => {
  if (questions.length < 1) {
    return 0
  }
  const score = calcPredictorWeekScore(questions)
  return score
}

/**
 *
 * Finding score of Pickem Week
 */
export const calcPredictorWeekScore = (questions: Question[]) => {
  let score: number = 0
  questions.map((q) => {
    q.answerChoices.map((a) => {
      if (a.correct === true) {
        score++
        return
      }
    })
  })
  return score
}

/**
 *
 * Retrieving human readable answer label
 */
export const getAnswerLabel = (
  question: Question,
  answerCode: string | number
) => {
  let matchedAnswer = question.answerChoices.find(
    (a) => a.code === answerCode
  )
  if (matchedAnswer) {
    return matchedAnswer.value
  } else {
    return
  }
}

/**
 * Find index of first unanswered question
 */

export const getFirstUnansweredQuestion = (
  questions: Question[],
  questionType: string
) => {
  if (questions.length < 1) {
    return 0
  }
  let filtered = filterQuestionsByType(questions, questionType)
  let firstUnanswered = filtered.findIndex((q) => !q.userAnswerCode)
  if (firstUnanswered === -1) {
    return filtered.length //Summary shows if all questions answered
  }
  return firstUnanswered
}

export const filterQuestionsByType = (
  questions: Question[],
  filterType: string
) => {
  let filtered = questions.filter((q) => q.type === filterType)
  return filtered
}
