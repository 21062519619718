//Analytics dash: https://www.notion.so/premierlacrosseleague/Analytics-Tags-Database-ca224c3268ec4c7c914c408b3e68b396?pvs=4
export const ANALYTICS_TAGS = {
  // Login //
  login_click: 'login_click',
  phone_login_click: 'phone_login_click',
  email_login_click: 'email_login_click',
  google_login_click: 'google_login_click',
  login_cancelled: 'login_cancelled',

  // User //
  fav_team_click: 'fav_team_click',

  // GAMIFICATION //
  code_claim_submit: 'code_claim_submit',
  code_claim_success: 'code_claim_success',
  code_claim_failure: 'code_claim_failure',
  daily_xp_claim: 'daily_xp_claim',
  achievements_tab_change: 'achievements_tab_change',
  achievement_item_click: 'achievement_item_click',
  benefits_item_click: 'benefits_item_click',
  benefits_tab_change: 'benefits_tab_change',

  // Fantasy //
  fantasy_create_team: 'fantasy_create_team',
  fantasy_share_click: 'fantasy_share_click',
  fantasy_tools_click: 'fantasy_tools_click',
  fantasy_rules_click: 'fantasy_rules_click',
  fantasy_team_submit: 'fantasy_team_submit',
  fantasy_player_add: 'fantasy_player_add',
  fantasy_player_drop: 'fantasy_player_drop',
  fantasy_player_click: 'fantasy_player_click',
  fantasy_action_button_click: 'fantasy_action_button_click',
  fantasy_clear_click: 'fantasy_clear_click',
  fantasy_team_clear: 'fantasy_team_clear',
  fantasy_week_select: 'fantasy_week_select',
  fantasy_how_to_click: 'fantasy_how_to_click',
  fantasy_challenge_click: 'fantasy_challenge_click',
  fantasy_accept_challenge: 'fantasy_accept_challenge',
  fantasy_challenger_row_click: 'fantasy_challenger_row_click',
  fantasy_accept_group: 'fantasy_accept_group',
  fantasy_group_join: 'fantasy_group_join',
  fantasy_group_create: 'fantasy_group_create',
  fantasy_group_invite: 'fantasy_group_invite',
  fantasy_group_row_click: 'fantasy_group_row_click',
  fantasy_ai_accept_click: 'fantasy_ai_accept_click',
  fantasy_ai_generate_click: 'fantasy_ai_generate_click',
  fantasy_ai_generate_noauth_click: 'fantasy_ai_generate_noauth_click',
  fantasy_ai_generate_again_click: 'fantasy_ai_generate_success',
  fantasy_competition_tab_click: 'fantasy_competition_tab_click',
  fantasy_competition_my_groups_enter_click:
    'fantasy_competition_my_groups_enter_click',
  fantasy_competition_enter_group_click:
    'fantasy_competition_enter_group_click',
  fantasy_competition_callout_click:
    'fantasy_competition_callout_click',
  fantasy_competition_create_group_click:
    'fantasy_competition_create_group_click',
  fantasy_remove_group_member: 'fantasy_remove_group_member',

  // SEASON //
  season_selection_change: 'season_selection_change',
  week_selection_change: 'week_selection_change',

  // VIDEO //
  video_start: 'video_start',
  video_watched: 'video_watched',

  //OTHER //
  home_banner_click: 'home_banner_click',
  banner_click: 'banner_click',
  app_callout_click: 'app_callout_click',
  feature_release_did_tap_primary_action: 'feature_release_did_tap_primary_action'
}
