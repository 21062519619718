import { PLL_CONFERENCES } from '../../Constants/season'
import { currentTime } from '../DateTime/dateTimeHelpers'
import { Decimal1DigitNum, getGameTimeLocal } from '../formatting'
import { SEASON_SEGMENT_NAMES } from '../Season/seasonHelpers'
import { getTeamBasicData } from '../Teams/teamHelpers'
import { fantasyPositionTypes, playerBonusFields } from '../../Configs'
import {
  AllEvents,
  CompiledPicksType,
  CondensedAIPlayer,
  Event,
  FantasyPlayer,
  FantasyPlayerStats,
  FantasyPositionTypes,
  FantasyUser,
  FantasyWeek,
  PlayerCount,
} from '../../Api'
import { matchPlayerInfo } from './fantasyPlayerHelpers'

export function Percent(value: number): string {
  let pctStr = `${(value * 100).toFixed()}`
  let cleaned = parseFloat(pctStr)
  return `${cleaned}%`
}

export function PercentFromStr(value: string): string {
  let num = parseFloat(value)
  let pctStr = `${(num * 100).toFixed()}`
  return `${pctStr}%`
}

export function Decimal1Digit(value: number): string {
  return `${Math.round(value * 10) / 10}`
}

export function ConvertStatValue<T>(
  value: T,
  valueName: string
): T | string {
  if (valueName.toLowerCase().endsWith('pct')) {
    if (typeof value === 'number') {
      return Percent(value)
    }
    if (typeof value === 'string') {
      return PercentFromStr(value)
    }

    if (value === null || value === undefined) return '-'
  } else if (
    valueName.toLowerCase().endsWith('aa') ||
    valueName.endsWith('PG')
  ) {
    if (typeof value === 'number') {
      return Decimal1Digit(value)
    }
    if (value === null || value === undefined) return '-'
  } else if (valueName === 'foRecord') {
    if (value === null || value === undefined) return '-'
    return value
  } else {
    if (value === null || value === undefined) return '0'
  }
  return value
}

export const getPosition = (
  pos: string,
  type: 'short' | 'long' = 'long'
) => {
  const positionPairs = {
    A: 'Attack',
    M: 'Midfield',
    D: 'Defense',
    G: 'Goalie',
    FO: 'Faceoff',
    F: 'Field',
  } as const

  const normalizedPos = pos?.toUpperCase() || ''

  if (type === 'short') {
    // If input is a long form, find its short form
    const shortForm = Object.keys(positionPairs).find(
      (key) => positionPairs[key].toUpperCase() === normalizedPos
    )
    return shortForm || 'Player'
  }

  // If input is a short form, get its long form
  return positionPairs[normalizedPos] || 'Player'
}

export const getCapLeft = (
  cap: number,
  picks: number[],
  allPlayers: FantasyPlayer[]
): number => {
  return picks.reduce((remainingCap, pickId) => {
    const player = matchPlayerInfo(pickId, allPlayers)
    return remainingCap - (player?.salary ?? 0)
  }, cap)
}

export const getTotalCap = (players: CondensedAIPlayer[]) => {
  const sum = players.reduce(
    (acc, p) => acc + (p?.fantasyInfo?.salary || 0),
    0
  )

  return sum
}

export const getTotalProj = (players: CondensedAIPlayer[]) => {
  const sum = players.reduce(
    (acc, p) => acc + (p?.fantasyInfo?.projectedPoints || 0),
    0
  )

  return Decimal1DigitNum(sum)
}

export const teamHasPlayerWithGameStarted = (
  picks: CompiledPicksType,
  events: Event[],
  allPlayers: FantasyPlayer[]
): boolean => {
  return Object.values(picks).some((positionPlayers) =>
    positionPlayers.some((player) =>
      hasGameStarted(player, events, allPlayers)
    )
  )
}

export const hasChampSeriesGameStarted = (
  player: FantasyPlayer,
  events: Event[]
) => {
  let now = Math.round(Date.now() / 1000)
  const currentGame = getCurrentGame(events, player)
  if (!currentGame) return false
  if (currentGame?.startTime < now) {
    //Has Started
    return true
  }

  return false
}

export const hasGameStarted = (
  playerId: number,
  events: Event[],
  allPlayers: FantasyPlayer[]
): boolean => {
  const player = matchPlayerInfo(playerId, allPlayers)
  if (!player) return false
  const currentTime = Math.round(Date.now() / 1000)
  const currentGame = getCurrentGame(events, player)
  if (!currentGame) return false
  const gameStarted = currentGame?.eventStatus > 1
  const gameStartPassed = currentGame?.startTime < currentTime
  return gameStarted && gameStartPassed
}

export const checkGamesStarted = (
  activeWeek: number,
  fantasyWeeks?: FantasyWeek[]
) => {
  if (!fantasyWeeks) return false
  let currentWeek = fantasyWeeks?.find(
    (week) => week.week === activeWeek
  )
  return currentWeek ? currentTime > currentWeek?.openTime : false
}

export const checkPlayerCount = (picks: CompiledPicksType) => {
  let picksArray = getPicksArray(picks)

  return picksArray.length
}

export const checkPlayerAlreadyAdded = (
  pick: FantasyPlayer,
  position: string,
  pickList: CompiledPicksType
) => {
  let posList = pickList[position]
  let foundPlayer = posList.find(
    (pl) => pl?.fantasyPlayerInfoId === pick?.fantasyPlayerInfoId
  )
  return foundPlayer ? true : false
}

export const getResultStatus = (
  playerTeam: number,
  oppTeam: number
) => {
  if (playerTeam > oppTeam) {
    return 'W'
  }

  return 'L'
}

export const getScoresArray = (
  player: FantasyPlayerStats,
  game: AllEvents
) => {
  const playerTeam = getTeamBasicData(player?.currentTeam?.officialId)
  if (playerTeam?.officialId === game.homeTeam.officialId) {
    return [game.homeScore, game.visitorScore]
  }
  return [game.visitorScore, game.homeScore]
}

export const getPlayerOpponent = (
  player: FantasyPlayer | FantasyPlayerStats,
  game?: Event | AllEvents
) => {
  //return opposite of player's team
  if (game?.seasonSegment === SEASON_SEGMENT_NAMES.allstar) {
    const playerConference = player.currentTeam?.conference
    if (!playerConference) return 'TBD'
    //Return the opposite conference
    return playerConference === PLL_CONFERENCES.EASTERN
      ? 'West'
      : 'East'
  }

  const playerTeam = player.currentTeam?.officialId
  const homeCode = game?.homeTeam?.officialId
  const awayCode = game?.awayTeam?.officialId

  if (!playerTeam || !homeCode || !awayCode) return 'TBD'

  return homeCode === playerTeam ? awayCode : homeCode
}

export const getGameFP = (
  eventId: string,
  player: FantasyPlayerStats
) => {
  if (!player?.fantasyInfo?.seasonTotalPts) return 0
  const gamePtsObj = player.fantasyInfo.seasonWeeklyPts?.find(
    (wk) => wk.eventId === eventId
  )
  return gamePtsObj?.totalPts || 0
}

/**
 * Adding bonus points if player has met the bonus metric
 */
export const calculateFantasyBonusForStat = (
  field: string,
  value: number
) => {
  let bonus = playerBonusFields.find((f) => f.field === field)
  if (!bonus) return 0
  if (value >= bonus.bonusMetric) return bonus.bonus
  return 0
}

export const getPosTypeObj = (posTypes?: PlayerCount) => {
  if (!posTypes) return []
  let posTypesArray: FantasyPositionTypes[] = [
    { label: 'All', value: 'All' },
  ]
  Object.keys(posTypes).map((pos) => {
    let type = fantasyPositionTypes.find(
      (posType) => posType.label.toLowerCase() === pos
    )
    if (type) {
      // Sort position types in specific order: All, A, M, D, FO, G
      const positionOrder = ['All', 'A', 'M', 'D', 'FO', 'G']
      posTypesArray.push({ label: type.label, value: type.value })
      posTypesArray.sort((a, b) => {
        const indexA = positionOrder.indexOf(a.value)
        const indexB = positionOrder.indexOf(b.value)
        return indexA - indexB
      })
    }
  })
  return posTypesArray
}

export const filterFantasyPlayers = (
  pos: string,
  allPlayers: FantasyPlayer[]
) => {
  let players: FantasyPlayer[] = []
  if (allPlayers.length < 1) return players

  if (pos === 'All') {
    players = allPlayers
  } else {
    players = allPlayers.filter(
      (plr) => mergePosition(plr?.position ?? 'A') === pos
    )
  }

  return players
}

export const mergePosition = (pos: string) => {
  if (!pos) return pos
  if (pos === 'SSDM' || pos === 'LSM') {
    return 'D'
  }
  return pos
}

export const checkCompiled = (
  compiled: CompiledPicksType,
  player?: FantasyPlayer
) => {
  if (!player) return false
  let position: string = player.position

  if (position === 'LSM' || position === 'SSDM') {
    position = 'D'
  }

  return compiled[position]?.find(
    (pick: number) => pick === player?.fantasyPlayerInfoId
  )
    ? true
    : false
}

export const determineActionButton = (
  player: FantasyPlayer,
  page: string,
  compiled: CompiledPicksType,
  events: Event[],
  allPlayers: FantasyPlayer[]
): string => {
  // Special cases that return immediately
  if (page === 'pTab') return 'arrow'
  if (player.currentTeam?.teamId === 'ZPP') return ''

  // Check if game is live
  const gameLive = hasGameStarted(
    player.fantasyPlayerInfoId,
    events,
    allPlayers
  )
  if (gameLive) {
    return page === 'pCard' ? '' : 'arrow'
  }

  // Handle different pages
  switch (page) {
    case 'team':
      return 'swap'
    case 'swap':
    case 'pCard':
      return checkCompiled(compiled, player) ? 'drop' : 'add'
    default:
      return ''
  }
}

export const getPicksArray = (picks: CompiledPicksType) => {
  return Object.values(picks).flat()
}

export const convertPositionForFantasy = (
  player: CondensedAIPlayer
) => {
  const pos = player?.fantasyInfo?.position
  if (!pos) return ''
  return mergePosition(pos)
}

export const getLeaderName = (leader: FantasyUser | null) => {
  if (!leader) return ''
  if (leader?.teamName) return leader.teamName
  if (leader?.name) return `SuperFan#${leader.name.slice(0, 4)}`
  return `SuperFan#${leader.firebaseId.slice(3, 6)}`
}

// Get the current game for a player
export const getCurrentGame = (
  seasonEvents: Event[],
  player?: FantasyPlayer
) => {
  if (!player) return undefined
  const currentGame = seasonEvents.find(
    (event) => event.eventId === player.eventId
  )
  return currentGame
}

// Get the display string for a player
export const getDisplayString = (player: FantasyPlayer, events: Event[]) => {
  // Return early if game has started
  if (player.eventStatus && player.eventStatus > 0) {
    return player.displayString ?? ''
  }

  // Get current game and return empty if no game or start time
  const currentGame = getCurrentGame(events, player)
  if (!currentGame?.startTime) {
    return ''
  }

  // Format time and combine with display string
  const localTime = getGameTimeLocal(currentGame.startTime)

  return `${player.displayString ?? ''} ${localTime}`
}