import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import { CondensedAIPlayer } from '../../Api/Fantasy/fantasy.types'
import { SeasonContext } from '../SeasonContext'
import { generateFantasyTeamWithAI } from '../../Api'
import { SnackbarContext } from '../SnackbarContext'
import { getTotalCap, getTotalProj, matchPlayerInfo } from '../../Utils'
import { submitFantasyTeam } from '../../Api/Fantasy/fantasy.api'
import { AuthContext } from '../../Api/firebase'
import { FantasyContext } from './FantasyContext'
import {
  checkRemainingGenTokensLocal,
  hasWeekendStarted,
  logEvent,
  updateRemainingGenTokensLocal,
} from '../../Utils'
import { FANTASY_GENERATE_WEEK_TOKENS } from '../../Constants/fantasy'
import { ANALYTICS_TAGS } from '../../Constants/analytics'

type TeamGeneratorContextType = {
  generating: boolean
  remainingGenerations: number
  generatedTeam: CondensedAIPlayer[]
  teamSummaryData: { salary: number; projected: number }
  generateTeamForWeek: () => Promise<void>
  saveTeamFromAI: () => Promise<boolean>
  clearGeneratedTeam: () => void
}

export const TeamGeneratorContext =
  createContext<TeamGeneratorContextType | null>(null)

export const TeamGeneratorProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  let { uid, login, isLoggedIn } = useContext(AuthContext)!
  let { allPlayers, setupPicksForWeek } = useContext(FantasyContext)!
  let { activeWeek, events } = useContext(SeasonContext)!
  let { toggleShowSnackbar } = useContext(SnackbarContext)!
  let [generating, setGenerating] = useState<boolean>(false)
  let [remainingGenerations, setRemainingGenerations] =
    useState<number>(FANTASY_GENERATE_WEEK_TOKENS)
  let [teamSummaryData, setTeamSummaryData] = useState<{
    salary: number
    projected: number
  }>({ salary: 0, projected: 0 })
  const [generatedTeam, setGeneratedTeam] = useState<
    CondensedAIPlayer[]
  >([])

  useEffect(() => {
    const tokens = checkRemainingGenTokensLocal(activeWeek)
    setRemainingGenerations(tokens)
  }, [activeWeek])

  const generateTeamForWeek = async () => {
    if (!isLoggedIn) {
      logEvent(ANALYTICS_TAGS.fantasy_ai_generate_noauth_click, {
        week: activeWeek,
      })

      return login()
    }
    logEvent(ANALYTICS_TAGS.fantasy_ai_generate_click, {
      week: activeWeek,
      generations_left: remainingGenerations,
    })
    const canContinue = continueAfterGenerateChecks()
    if (!canContinue) return Promise.resolve()

    setGenerating(true)
    if (generatedTeam.length > 0) {
      setGeneratedTeam([])
    }

    const team = await generateFantasyTeamWithAI(activeWeek)
    if (team.length > 0) {
      setGeneratedTeam(team)
      updateSummaryData(team)
      const newGeneratioCredit = remainingGenerations - 1
      setRemainingGenerations(newGeneratioCredit)
      updateRemainingGenTokensLocal(newGeneratioCredit, activeWeek)
    } else {
      toggleShowSnackbar({
        text: 'Failed to generate team. Please try again.',
        color: 'red',
      })
    }
    setGenerating(false)
  }

  const updateSummaryData = (team: CondensedAIPlayer[]) => {
    const salary = getTotalCap(team)
    const projected = getTotalProj(team)
    setTeamSummaryData({ salary, projected })
  }

  const saveTeamFromAI = async () => {
    const ids = generatedTeam.map(
      (player) => {
      const fantasyData = matchPlayerInfo(player.officialId, allPlayers)
      return fantasyData?.fantasyPlayerInfoId ?? 0
    })
    if (!uid) {
      //Show error snackbar
      login()
      return false
    }
    let success = await submitFantasyTeam(uid, activeWeek, ids)
    if (!success) {
      //Show error snackbar
      toggleShowSnackbar({
        text: 'There was an error submitting lineup.',
        color: 'red',
      })
      return false
    }
    toggleShowSnackbar({
      text: 'Added lineup!',
      color: 'green',
    })
    setupPicksForWeek(ids)
    clearGeneratedTeam()
    return true
  }

  const clearGeneratedTeam = () => {
    setGeneratedTeam([])
    setTeamSummaryData({ salary: 0, projected: 0 })
  }

  const continueAfterGenerateChecks = () => {
    const gamesAlreadyStarted = hasWeekendStarted(events, activeWeek)
    if (gamesAlreadyStarted) {
      toggleShowSnackbar({
        text: 'Cannot use PLL AI after games have started.',
        color: 'red',
      })
      return false
    }
    if (remainingGenerations < 1) {
      toggleShowSnackbar({
        text: 'No more credits this week to generate',
        color: 'red',
      })
      return false
    }
    return true
  }

  return (
    <TeamGeneratorContext.Provider
      value={{
        generating,
        remainingGenerations,
        generatedTeam,
        teamSummaryData,
        generateTeamForWeek,
        saveTeamFromAI,
        clearGeneratedTeam,
      }}
    >
      {children}
    </TeamGeneratorContext.Provider>
  )
}
